function WrapDate(calendar) {
    var me = this;
    this.gregorianDate = new Date();
    this.jalaliDate = new persianDate;

    var gregorianMonthsDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    var jalalianMonthsDays = [31, 31, 31, 31, 31, 31, 30, 30, 30, 30, 30, 29];
    var days = '<option value="day">' + trans('Day') + '</option>';
    for (i = 1; i <= 31; i++) {
        days += '<option value="' + i + '">' + i + '</option>';
    }

    this.getYear = function () {
        return this[calendar + 'Year']();
    }

    this.gregorianYear = function () {
        return (me.gregorianDate).getFullYear();
    }

    this.jalaliYear = function () {
        return (me.jalaliDate).year();
    }

    this.getMonth = function () {
        return this[calendar + 'Month']();
    }

    this.gregorianMonth = function () {
        return (me.gregorianDate).getMonth();
    }

    this.jalaliMonth = function () {
        var month = (me.jalaliDate).month();
        return (month - 1);
    }

    this.daysInMonth = function (...args) {
        return this[calendar + 'DaysInMonth'](...args);
    }

    this.gregorianDaysInMonth = function (year, month) {
        return (new Date(year, month, 0)).getDate();
    }

    this.jalaliDaysInMonth = function (year, month) {
        return new persianDate([year, (month)]).daysInMonth();
    }

    this.getCurrentDate = function () {
        return this[calendar + 'GetCurrentDate']();
    }

    this.gregorianGetCurrentDate = function () {
        return (me.gregorianDate).toISOString().split('T')[0];
    }

    this.jalaliGetCurrentDate = function () {
        return (me.jalaliDate).toLocale('en').format('YYYY-MM-DD');
    }

    this.getDay = function (...args) {
        return this[calendar + 'GetDay'](...args);
    }

    this.gregorianGetDay = function (year, month) {
        return (new Date(year, month)).getDay()
    }

    this.jalaliGetDay = function (year, month) {
        return (new persianDate([year, (month + 1), 1])).day();
    }

    this.getCurrentMonth = function (...args) {
        return this[calendar + 'GetCurrentMonth'](...args);
    }

    this.gregorianGetCurrentMonth = function (date) {
        return (new Date(date)).getMonth();
    }

    this.jalaliGetCurrentMonth = function (date) {
        date = date.split('-');
        var month = (new persianDate([parseInt(date[0]), parseInt(date[1]), parseInt(date[2])])).month();
        return (month - 1);
    }

    this.getNextDate = function (...args) {
        return this[calendar + 'GetNextDate'](...args);
    }

    this.gregorianGetNextDate = function (hours) {
        return new Date((new Date()).getTime() + hours * 60 * 60 * 1000).toISOString().split("T")[0];
    }

    this.jalaliGetNextDate = function (hours) {
        return (new persianDate()).add('h', hours).toLocale('en').format('YYYY-MM-DD');
    }

    this.rangeNameMonths = function () {
        return this[calendar + 'RangeNameMonths']();
    }

    this.gregorianRangeNameMonths = function () {
        return ['1-January', '2-February', '3-March', '4-April', '5-May', '6-June', '7-July', '8-August', '9-September', '10-October', '11-November', '12-December'];
    }

    this.jalaliRangeNameMonths = function () {
        return persianDate.rangeName().months;
    }

    this.rangeNameWeekdays = function () {
        return this[calendar + 'RangeNameWeekdays']();
    }

    this.gregorianRangeNameWeekdays = function () {
        return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    }

    this.jalaliRangeNameWeekdays = function () {
        return persianDate.rangeName().weekdaysMin;
    }

    this.weekdayPosition = function (...args) {
        return this[calendar + 'WeekdayPosition'](...args);
    }

    this.gregorianWeekdayPosition = function (weekday, i) {
        return (Math.floor((weekday + i - 1) / 7));
    }

    this.jalaliWeekdayPosition = function (weekday, i) {
        return Math.floor(((weekday - 1) + i - 1) / 7);
    }

    this.colspanBeforeWeekday = function (...args) {
        return this[calendar + 'ColspanBeforeWeekday'](...args);
    }

    this.gregorianColspanBeforeWeekday = function (weeks, weekday) {
        if (weekday > 0) {
            weeks[0] = '<td colspan="' + weekday + '"></td>';
        }
        return weeks;
    }

    this.jalaliColspanBeforeWeekday = function (weeks, weekday) {

        if (weekday > 1) {
            weeks[0] = '<td colspan="' + (weekday - 1) + '"></td>';
        }
        return weeks;
    }

    this.getPreviousYears = function () {
        return this[calendar + 'GetPreviousYears']();
    }

    this.gregorianGetPreviousYears = function () {
        var currentYear = me.getYear(), startYear = currentYear - 100, years = [];
        while (startYear <= currentYear) {
            years.push(startYear++);
        }
        return years;
    }

    this.jalaliGetPreviousYears = function () {

        var currentYear = me.getYear(), startYear = currentYear - 100, years = [];
        while (startYear <= currentYear) {
            years.push(startYear++);
        }
        return years;
    }

    this.getNextYears = function () {
        return this[calendar + 'GetNextYears']();
    }

    this.gregorianGetNextYears = function () {
        var currentYear = me.getYear(), endYear = currentYear + 10, years = [];
        while (currentYear <= endYear) {
            years.push(currentYear++);
        }
        return years;
    }

    this.jalaliGetNextYears = function () {

        var currentYear = me.getYear(), endYear = currentYear + 10, years = [];
        while (currentYear <= endYear) {
            years.push(currentYear++);
        }
        return years;
    }

    this.getDays = function () {
        return this[calendar + 'GetDays']();
    }

    this.gregorianGetDays = function () {
        return gregorianMonthsDays;
    }

    this.jalaliGetDays = function () {
        return jalalianMonthsDays;
    }

    this.setDays = function () {
        return this[calendar + 'SetDays']();
    }

    this.gregorianSetDays = function () {
        return days;
    }

    this.jalaliSetDays = function () {
        return days;
    }

    this.isLeapYear = function (...args) {
        return this[calendar + 'IsLeapYear'](...args);
    }

    this.gregorianIsLeapYear = function (year) {
        var leap = new Date(year, 1, 29).getDate() === 29;
        if (leap) {
            return true;
        }
        return false;
    }

    this.jalaliIsLeapYear = function (year) {
        if (new persianDate([year]).isLeapYear()) {
            return true;
        }
        return false;
    }

    this.changeMonth = function (...args) {
        return this[calendar + 'ChangeMonth'](...args);
    }

    this.gregorianChangeMonth = function (select) {
        var month = parseInt($(select).val()) - 1;
        var day = $(select).closest('.months').prev().find('.day');
        var selectedDay = $(day).val();
        $(day).empty();
        var option = '<option value="day">day</option>';
        for (var i = 1; i <= gregorianMonthsDays[month]; i++) {
            option += '<option value="' + i + '">' + i + '</option>';
        }
        $(day).append(option);
        if (parseInt(selectedDay) > gregorianMonthsDays[month]) {
            selectedDay = 1;
        }
        $(day).val(selectedDay);
    }

    this.jalaliChangeMonth = function (select) {
        var month = parseInt($(select).val()) - 1;
        var day = $(select).closest('.months').prev().find('.day');
        var selectedDay = $(day).val();
        $(day).empty();
        var option = '<option value="day">day</option>';
        for (var i = 1; i <= jalalianMonthsDays[month]; i++) {
            option += '<option value="' + i + '">' + i + '</option>';
        }
        $(day).append(option);
        if (parseInt(selectedDay) > jalalianMonthsDays[month]) {
            selectedDay = 1;
        }
        $(day).val(selectedDay);
    }

    this.changeYear = function (...args) {
        return this[calendar + 'ChangeYear'](...args);
    }

    this.gregorianChangeYear = function (select) {
        if (me.isLeapYear($(select).val())) {
            gregorianMonthsDays[1] = 29;
        } else {
            gregorianMonthsDays[1] = 28;
        }
        var month = $(select).closest('.years').prev().find('.month').val();
        if (month == 2) {
            var day = $(select).closest('.years').prev().prev().find('.day');
            var selectedDay = $(day).val();
            $(day).empty();
            var option = '<option value="day">day</option>';
            for (var i = 1; i <= gregorianMonthsDays[1]; i++) { //add option days
                option += '<option value="' + i + '">' + i + '</option>';
            }
            $(day).append(option);
            if (parseInt(selectedDay) > jalalianMonthsDays[(parseInt(month) - 1)]) {
                selectedDay = 1;
            }
            $(day).val(selectedDay);
        }
    }

    this.jalaliChangeYear = function (select) {
        if (me.isLeapYear($(select).val())) {
            jalalianMonthsDays[11] = 30;
        } else {
            jalalianMonthsDays[11] = 29;
        }
        var month = $(select).closest('.years').prev().find('.month').val();
        if (month == 12) {
            var day = $(select).closest('.years').prev().prev().find('.day');
            var selectedDay = $(day).val();
            $(day).empty();
            var option = '<option value="day">day</option>';
            for (var i = 1; i <= jalalianMonthsDays[11]; i++) { //add option days
                option += '<option value="' + i + '">' + i + '</option>';
            }
            $(day).append(option);
            if (parseInt(selectedDay) > jalalianMonthsDays[(parseInt(month) - 1)]) {
                selectedDay = 1;
            }
            $(day).val(selectedDay);
        }
    }

    this.abbreviationNameOfMonths = function () {
        return this[calendar + 'AbbreviationNameOfMonths']();
    }

    this.gregorianAbbreviationNameOfMonths = function () {
        return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    }

    this.jalaliAbbreviationNameOfMonths = function () {
        return persianDate.rangeName().months;
    }

    this.getFirstDay = function (...args) {
        return this[calendar + 'GetFirstDay'](...args);
    }

    this.gregorianGetFirstDay = function (date) {
        var date = new Date(date);
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        return date.getFullYear() + '-' + (((date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1))) + '-' + ('0' + firstDay.getDate());

    }

    this.jalaliGetFirstDay = function (date) {
        var date = (date).split('-');
        return dayjs((new persianDate([parseInt(date[0]), parseInt(date[1]), 1])).toDate()).format("YYYY-MM-DD");
    }

    this.getLastDay = function (...args) {
        return this[calendar + 'GetLastDay'](...args);
    }

    this.gregorianGetLastDay = function (date) {
        var date = new Date(date);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return date.getFullYear() + '-' + (((date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1))) + '-' + lastDay.getDate();
    }

    this.jalaliGetLastDay = function (date) {
        var date = (date).split('-');
        var lastDay = new persianDate([parseInt(date[0]), parseInt(date[1])]).daysInMonth();
        return dayjs((new persianDate([parseInt(date[0]), parseInt(date[1]), lastDay])).toDate()).format("YYYY-MM-DD");
    }
}

