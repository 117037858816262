function CustomSelect(selector, config) {
    var $element = $(selector);

    switch ($element.length) {
        case 0:
            return;
        case 1:
            break;

        default:
            $element.each((index, item) => {
                new CustomSelect(item, config)
            });
            return;
    }

    config = config || {};
    config = $.extend({
        url: null,
        name: "",
    }, config);
    var me = this;
    $element.hide();
    var $container = $(`<div class="wrapper"><button class="theme-search-area-section-inner cursor-pointer wrapper-btn" type="button"><i class="theme-search-area-section-icon lin lin-location-pin"></i>${$element.data('placeholder')}</button><div class="custom-select"><div class="custom-select-inputs margin-t-20-tablet-and-mobile">
                            <input type="text" class="custom-select-input" placeholder="${$element.data('placeholder')}" data-value=""><i class="fa fa-times-circle clear-input-content d-none"></i><input type="hidden" name="${$element.data('name') || config.name}" value=""></div>
                            <div class="text-center p-10 fs-16">${trans(config.message)}</div>
                            <ul class="custom-select-results"></ul>
                            <ul class="airport-recomended"></ul>
                        </div>
                    </div>`
    );

    $element.after($container).appendTo($container);

    var wrapperBtn = $container.find('.wrapper-btn');
    var wrapperContent = $container.find('.custom-select');
    var results = $container.find('.custom-select-results');
    var inputIsText = $container.find('.custom-select-input');
    var inputIsHidden = $container.find('input[type=hidden]');
    var clearInputContent = $container.find('.clear-input-content');
    var inputsSection = $container.find('.custom-select-inputs');
    var timer;

    wrapperBtn.click(function () {
        wrapperContent.data('trash', 'showing');
        wrapperContent.fadeIn(undefined, () => wrapperContent.data('trash', 'show'));
        inputIsText.focus();
        if(inputIsText.val() == "") {
            $container.find('.airport-recomended').show().find("li").first().addClass("selected");
            results.html("");
        } else {
            $container.find('.airport-recomended').hide();
        }
    });

    this.makeRecomendedAirport = function() {
        var loginInformation = getStorage('loginInformation');
        var lang = dataActive.location.query.lang;
        var recomendedAirport = JSON.parse(localStorage.getItem("airportRecomended"+ lang));
        $container.find('.airport-recomended').html('');
        if(!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
            if(recomendedAirport !== null) {
                recomendedAirport.forEach((airport,i) => {
                    var li = $(`<li class="p-10 ${i == 0 ? 'selected' : ''}" data-value="${airport.value}"> ${airport.title} </li>`);
                    li.hover(
                        function () {
                            $(this).addClass("selected");
                        },
                        function () {
                            $(this).removeClass('selected');
                        }
                    );
                    li.click(function () {
                        me.selectedItem(this);
                    });
                    $container.find('.airport-recomended').append(li);
                });
            }
        } else {
            if(!$.isEmptyObject(localStorage.getItem("popularFlights"))) {
                let popularFlights = JSON.parse(localStorage.getItem("popularFlights"));
                let popularAirports = [];
                let popularAirportsId = [];
                popularFlights.forEach(flight => {
                    if(!popularAirportsId.includes(flight.origin.airport_buffer_id) && popularAirportsId.length < 5) {
                        popularAirportsId.push(flight.origin.airport_buffer_id);
                        popularAirports.push({originTitle: flight.origin.airport_buffer_title , originId: flight.origin.airport_buffer_id});
                    }
                });
                popularAirports.forEach((airport,i) => {
                    let li = $(`<li class="p-10 ${i == 0 ? "selected" : ""}" data-value="${airport.originId}"> ${airport.originTitle} </li>`);
                    li.hover(
                        function () {
                            $(this).addClass("selected");
                        },
                        function () {
                            $(this).removeClass('selected');
                        }
                    );
                    li.click(function () {
                        me.selectedItem(this);
                    });
                    $container.find('.airport-recomended').append(li);
                });
            }
        }
    }

    inputIsText.on('keyup', function (e) {
        if(inputIsText.val() == "") {
            $container.find('.airport-recomended').show().find("li").first().addClass("selected");
            results.html("");
        } else {
            $container.find('.airport-recomended').hide();
        }
        me.changeSearch();
        var inputVal = inputIsText.val();
        if (inputVal == inputIsText.data('value')) {
            return;
        }
        inputIsText.data('value', inputIsText.val());
        if (inputVal.length < 3) {
            timer = clearTimeout(timer);
            return;
        }

        if (timer === undefined && e.keyCode != 8 && e.keyCode != 46) {
            timer = setTimeout(me.fetch, 1000);
        }

    });

    this.fetch = function () {
        let inputVal = inputIsText.val();
        if (inputVal.length < 3) {
            timer = clearTimeout(timer);
            return;
        }
        $.ajax({
            url: config.url,
            method: 'GET',
            data: {
                q: inputVal, // search term
            },
            cache: false,
            success: function (data) {
                results.empty();
                me.mapData(data.data);
            },
            error: function (data) {
            }

        }).done(function () {
            timer = clearTimeout(timer);
        });
    }

    $container.on('keydown', function (e) {
        var current = $container.find('.selected');
        if (e.which == 38) {
            // UP KEY
            var prev = current.prev('li');
            if (prev.length) {
                current.removeClass('selected');
                prev.addClass('selected');
            }
        } else if (e.which == 40) {
            // DOWN KEY
            var next = current.next('li');
            if (next.length) {
                current.removeClass('selected');
                next.addClass('selected');
            }
        }
    });

    $container.on('keyup', function (e) {
        if (e.which == 13) {
            // ENTER KEY
            inputIsText.blur();
            var selected = $container.find('.selected');
            me.selectedItem(selected);
        }
    });

    $('html').click(function (e) {
        var $element = $(e.target);
        if (wrapperContent.data('trash') == 'show' && !wrapperContent.is($element) && wrapperContent.has($element).length == 0) {
            wrapperContent.hide();
        }

        if (!$container.find('.selected').length) {
            inputIsText.val('');
            results.empty();
            clearInputContent.addClass('d-none');
            results.prev().show();
        }
    });

    this.mapData = function (data) {
        if (!$.isEmptyObject(data)) {
            data.forEach((item, index) => {
                var li = $('<li class="p-10 ' + (index == 0 ? 'selected' : '') + '" data-value="' + item.id + '" >' + item.title + '</li>');
                li.hover(
                    function () {
                        $(this).addClass("selected");
                    },
                    function () {
                        $(this).removeClass('selected');
                    }
                );
                li.click(function () {
                    me.selectedItem(this);
                });
                results.prev().hide();
                results.append(li);
            });
        }
    }

    this.selectedItem = function (element) {
        var selected = $(element);
        if (selected.length) {
            wrapperBtn.css('width', '90%');
            wrapperBtn.html('<i class="theme-search-area-section-icon lin lin-location-pin"></i>' + selected.text());
            if (!$container.find('.clear-selected').length) {
                wrapperBtn.after('<i class="fa fa-times clear-selected"></i>');
            }
            inputIsHidden.val(selected.data('value'));
            inputIsHidden.data('title', (selected.text()));
            inputIsText.val(selected.text());
            results.empty();
            var li = $('<li class="p-10  selected" data-value="' + selected.data('value') + '">' + selected.text() + '</li>');
            results.append(li);
            li.click(function () {
                me.selectedItem(this);
            });
        }
        me.changeSearch();
        wrapperContent.hide();
    }


    this.changeSearch = function () {

        var clearSelectedBtn = $container.find('.clear-selected');
        clearSelectedBtn.click(function () {
            $(this).remove();
            wrapperContent.data('trash', 'showing');
            wrapperContent.fadeIn(undefined, () => wrapperContent.data('trash', 'show'));
            wrapperBtn.html('<i class="theme-search-area-section-icon lin lin-location-pin"></i>' + $element.data('placeholder'));
            inputIsHidden.val('');
            inputIsText.val('');
            inputIsText.focus();
            results.empty();
            results.prev().show();
            clearInputContent.addClass('d-none');
            $container.find(".airport-recomended").show().find("li").first().addClass("selected");
        });

        clearInputContent.removeClass('d-none');
        clearInputContent.click(function () {
            $(this).addClass('d-none');
            clearSelectedBtn.remove();
            wrapperBtn.html('<i class="theme-search-area-section-icon lin lin-location-pin"></i>' + $element.data('placeholder'));
            inputIsHidden.val('');
            inputIsText.val('');
            inputIsText.focus();
            results.html('');
            $container.find(".airport-recomended").show().find("li").first().addClass("selected");

        });

    }

    this.isMobileWidth = function () {

        if (window.matchMedia('(max-width: 767px)').matches) {
            inputsSection.prepend('<button type="button" class="csm-close">&#215;</button>');
        }

        inputsSection.find('.csm-close').click(function () {
            wrapperContent.hide();
        });
    }

    this.init = function () {
        if ($element.val() != '') {
            var li = '<li class="p-10  selected" data-value="' + $element.data('value') + '">' + $element.data('title') + '</li>';
            me.selectedItem(li);
            results.prev().hide();

        }
        this.isMobileWidth();
        if($element.parents("form").data("name") == "FlightSearch") {
            me.makeRecomendedAirport();
        }
    }
    this.init();

}