class Hook {
    hooks = {}
    register(hookName, callBack) {
        this.hooks[hookName] = this.hooks[hookName] || [];
        this.hooks[hookName].push(callBack);
    }

    clear(hookName) {
        this.hooks[hookName] = [];
    }

    reset() {
        this.hooks = {};
    }

    call(hookName, configs) {
        var hook = this.hooks[hookName] || [];
        for (var index in hook) {
            configs = hook[index](configs);
        }
        return configs;
    }

    load(logics = {}) {
        for (let key in logics) {
            this.register(key, logics[key])
        }
    }
}