const routeList = {
    root: { uri: '/', redirectTo: 'index' },
    index: { title: "Home", uri: "/home", path: 'pages/home', middlewares: [] },
    hotelIndex: { title: "Hotels", uri: "/hotel", path: 'pages/hotel', middlewares: [] },
    hotelResult: { title: "Hotels Search", uri: "/hotel-result", path: 'pages/hotel-result', middlewares: [] },
    hotelProfile: { title: "Hotel Profile", uri: "/hotel-page", path: 'pages/hotel-page', },
    passengerInfo: { title: "Passenger Info", uri: "/passenger-info", path: 'pages/passenger-info', middlewares: [] },
    loginPage: { title: "Login", uri: "/login", path: 'pages/login', middlewares: [] },
    hotelVoucher: { title: "Hotel Voucher", uri: "/hotel-voucher", path: 'pages/hotel-voucher', middlewares: [] },
    hotelVoucherCustom: { title: "Hotel Voucher", uri: "/hotel-voucher-custom", path: 'pages/hotel-voucher', middlewares: [] },
    packageIndex: { title: "Packages", uri: "/package", path: 'pages/package', middlewares: [] },
    packageResult: { title: "Packages Search", uri: "/package-result", path: 'pages/package-result', middlewares: [] },
    packageProfile: { title: "Package Profile", uri: "/package-page/{referenceId}", path: 'pages/package-page', middlewares: [] },
    packageDirectProfile: { title: "Package Profile", uri: "/package-profile/{id}", path: 'pages/package-page', middlewares: [] },
    packageApiOutProfile: { title: "Package Profile", uri: "/package-profile/{id}/{referenceId}", path: 'pages/package-page', middlewares: [] },
    flightIndex: { title: "Flights", uri: "/flight", path: 'pages/flight', middlewares: [] },
    flightResult: { title: "Flights Search", uri: "/flight-result", path: 'pages/flight-result', middlewares: [] },
    packageVoucher: { title: "Package Voucher", uri: "/package-voucher", path: 'pages/package-voucher', middlewares: [] },
    flightVoucher: { title: "Flight Voucher", uri: "/flight-voucher", path: 'pages/flight-voucher', middlewares: [] },
    flightTicket: { title: "Flight Ticket", uri: "/flight-ticket", path: 'pages/flight-ticket', middlewares: [] },
    commingSoon: { title: "Comming Soon", uri: "/coming-soon", path: 'pages/coming-soon', middlewares: [] },
    registerPage: { title: "Register", uri: "/register", path: 'pages/register', middlewares: [] },
    visaResult: { title: "Visa Search", uri: "/visa-result", path: 'pages/visa-result', middlewares: [] },
    visaProfile: { title: "Visa Profile", uri: "/visa-page/{referenceId}", path: 'pages/visa-page', middlewares: [] },
    visaDirectProfile: { title: "Visa Profile", uri: "/visa-profile/{id}", path: 'pages/visa-page', middlewares: [] },
    visaApiOutProfile: { title: "Visa Profile", uri: "/visa-profile/{id}/{referenceId}", path: 'pages/visa-page', middlewares: [] },
    visaIndex: { title: "Visa", uri: "/visa", path: 'pages/visa', middlewares: [] },
    visaVoucher: { title: "Visa Voucher", uri: "/visa-voucher", path: 'pages/visa-voucher', middlewares: [] },
    postPage: { title: "Post", uri: "/post/{slug}", path: 'pages/post', middlewares: [] },
    blogindex: { title: "Blog", uri: "/blog", path: 'pages/blog', middlewares: [] },
    postByKeywords: { title: "Keywords", uri: "/keywords", path: 'pages/keywords', middlewares: [] },
    tourIndex: { title: "Tours", uri: "/tour", path: 'pages/tour', middlewares: [] },
    tourResult: { title: "Tours Search", uri: "/tour-result", path: 'pages/tour-result', middlewares: [] },
    tourProfile: { title: "Tour Profile", uri: "/tour-page", path: 'pages/tour-page', middlewares: [] },
    tourVoucher: { title: "Tour Voucher", uri: "/tour-voucher", path: 'pages/tour-voucher', middlewares: [] },
    eventIndex: { title: "Events", uri: "/event", path: 'pages/event', middlewares: [] },
    eventResult: { title: "Events Search", uri: "/event-result", path: 'pages/event-result', middlewares: [] },
    eventProfile: { title: "Event Profile", uri: "/event-page", path: 'pages/event-page', middlewares: [] },
    eventVoucher: { title: "Event Voucher", uri: "/event-voucher", path: 'pages/event-voucher', middlewares: [] },
    resetPassword: { title: "Reset Password", uri: "/reset-password", path: 'pages/reset-password', middlewares: [] },
    changePassword: { title: "Change Password", uri: "/change-password", path: 'pages/change-password', middlewares: [] },
    manualVoucher: { title: "Invoice", uri: "/manual-invoice", path: 'pages/manual-voucher', middlewares: [] },
    checkLogin: { title: "Check Login", uri: "/checklogin", middlewares: [] },
    logout: { title: "Logout", uri: "/logout", path: 'pages/logout', middlewares: [] },
    trackOrder: { title: "Track Order", uri: "/trackorder", path: 'pages/track-order', middlewares: [] },
    userBook: { title: "MY BOOKINGS", uri: "/my-bookings", path: 'pages/my-bookings-index', middlewares: [] },
    userProfile: { title: "MY PROFILE", uri: "/my-profile", path: 'pages/my-profile-index', middlewares: [] },
    changePasswordInMyProfile: { title: "CHANGE PASSWORD", uri: "/change-password-in-my-profile", path: 'pages/change-password-in-my-profile-index', middlewares: [] },
    chargeEwalletInMyProfile: { title: "CHARGE E_WALLET", uri: "/ewallet-charge", path: 'pages/ewallet-charge-in-my-profile-index', middlewares: [] },
    pageNotFound: { title: "Page Not Found", uri: "/404", path: 'pages/404', middlewares: [] },
    hotelsIndex: {title: "Hotels", uri: "/hotels", path: "pages/hotels", middlewares: []},
    airlines: {title: "Airlines", uri: "/airline", path: "pages/airlines", middlewares: []},
    airports: {title: "Airports", uri: "/airport", path: "pages/airports", middlewares: []},
    cities: {title: "Cities", uri: "/city", path: "pages/cities", middlewares: []},
    landmarks: {title: "Landmarks", uri: "/landmark", path: "pages/landmarks", middlewares: []}
};