
function LogRegistration(dataActive) {
    var pId = null;
    var me = this;

    this.getPid = function () {
        return pId;
    }

    function resetPid() {
        pId = Math.random().toString(36).replace('0.', '');
    }

    this.call = function (type, message, data) {
        if (typeof data != "object") {
            data = {
                "serialize": [JSON.stringify(data)]
            };
        } else {
            var serialized = {};
            for (var index in data) {
                serialized[index] = JSON.stringify(data[index]);
            }
            data = {
                "serialize": serialized
            };
        }
        $.ajax({
            url: dataActive.location.marketplace + '/v1/sendlug?pid=' + pId,
            method: "post",
            data: {
                type: type,
                message: message,
                serialize: data.serialize
            },
            success: function (data) {
            },
            error: function () {
            }
        })
    }

    this.info = function (message, data) {
        return me.call("info", message, data);
    }

    this.error = function (message, data) {
        return me.call("error", message, data);
    }

    this.warning = function (message, data) {
        return me.call("warning", message, data);

    }

    this.showPid = function () {
        var prev = localStorage.getItem('pid') || '';
        $(".pId").append($("<span class='pid' >PID: " + pId + "_" + prev + "</span>"));
        localStorage.setItem('pid', pId);
    }

    dataActive.hooks.register('beforeAjax', function (configs) {
        configs.url += (configs.url.indexOf('?') > -1 ? '&' : '?') + "pid=" + pId;
        return configs;
    });

    dataActive.hooks.register('beforePageRoute', function (configs) {
        resetPid();
        return configs;
    });

    resetPid();
}