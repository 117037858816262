function auth() {
    var loginInformation = getStorage("loginInformation");
    if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
        var check = false;
        ajax({
            url: dataActive.location.marketplace + "/v1/" + loginInformation.token + "/checkuserlogin",
            method: "get",
            async: false,
            success: function (data) {
                check = data.status == 1;
            },
            error: function () { check = false; }
        });
        if (check) {
            return;
        }
    }
    setStorage("redirectAfterLogin", dataActive.location);

    return dataActive.pageRoute("loginPage");
}