function HotelQuantitySelector(guests) {
    guests = guests || [{ adult: 1, child: 0, childage: [] }];
    var $input = $(".quantity-selector-input");
    $input.after(view("hotel/quantity-selector-box"));
    var $quantitySelector = $input.parent().parent();
    var $box = $quantitySelector.find(".quantity-selector-box");
    $input.click(function () {
        $box.show();
    });
    for (var i = 0; i < guests.length; i++) {
        addRoom(null, guests[i].adults || 1, guests[i].children || 0, guests[i]["child-age"] || []);
    }

    $('html').click(function (event) {
        var $element = $(event.target);
        if ($quantitySelector.has($element).length == 0 && $element.closest('.close').length == 0) {
            $box.hide();
        }
    });

    $quantitySelector.find('.hotel-qs-done-btn').click(function (event) {
        $(event.target).closest('.quantity-selector-box').hide();
    });

    var $addRoom = $box.find('.quantity-selector-add-room');
    $addRoom.click(addRoom);

    function addRoom(event, adult, child, childAge) {
        var rooms = $box.find('.quantity-selector-inner').length;
        var $addRoom = $box.find('.quantity-selector-add-room');
        var $roomView = $(view("hotel/quantity-selector-box-room-section", {
            rooms: rooms,
            adult: adult || 1,
            child: child || 0,
            childAge: Object.values(childAge || []),
        }));
        $roomView.find(".close").click(function () {
            if ($box.find('.quantity-selector-inner').length > 1) {
                $(this).closest('.quantity-selector-inner').remove();
                calculate();
            }
        });

        $roomView.find(".quantity-selector-increment").click(quantityIncrement);
        $roomView.find(".quantity-selector-decrement").click(quantityDecrement);
        $addRoom.before($roomView);
        calculate();
    }

    function quantityIncrement() {
        var $this = $(this);
        var $current = $this.prev('.quantity-selector-current');
        var type = $this.closest('.selector-type').find('p').text();
        var currentNumber = parseInt($current.text());
        ///> Number of adults less then 14 in each room.
        if (type == trans('Adults') && currentNumber > 6) {
            return;
        }
        ///> Number of childern less then 6 in each room.
        if (type == trans('Children') && currentNumber > 2) {
            return;
        }
        ///> Age less then 17 is children.
        if (type == trans('Child_age') && currentNumber >= 17) {
            return;
        }

        currentNumber++;
        $current.text(currentNumber);

        var $selectorInput = $this.closest('.selector-type').find('input');
        $selectorInput.val(currentNumber);

        if (type == trans('Children')) {
            var childrenNumber = currentNumber;
        }
        if ($this.hasClass('child-increment')) {
            var $childView = $(view("hotel/quantity-selector-box-child-section", { age: 0 }));
            $childView.find(".quantity-selector-increment").click(quantityIncrement);
            $childView.find(".quantity-selector-decrement").click(quantityDecrement);
            for (var i = 0; i < childrenNumber; i++) {
                $childView.find(".child-age-input").attr('name', $selectorInput.attr('name').replace('children]', 'child-age][' + (i + 1) + ']'));
            }
            $childView.find(".child-age-input").val('0');
            $this.closest('.quantity-selector-inner').find('.child-age').append($childView);

        }

        calculate();

    }

    function quantityDecrement() {
        var $this = $(this);
        var $current = $this.next('.quantity-selector-current');
        var type = $this.closest('.selector-type').find('p').text();
        var currentNumber = parseInt($current.text());
        if (type == trans('Adults') && currentNumber < 2) {
            return;
        }
        if (currentNumber == 0) {
            return;
        }
        currentNumber--;
        $current.text(currentNumber);
        var $selectorInput = $this.closest('.selector-type').find('input');
        $selectorInput.val(currentNumber);
        if ($this.hasClass('child-decrement')) {
            $this.closest('.quantity-selector-inner').find('.child-age').children().last().remove();
        }
        calculate();
    }

    function calculate() {
        var adults = 0;
        $box.find('.selector-type-adult').each(function (index, item) {
            adults += parseInt($(item).text());
        });
        children = 0;
        $box.find('.selector-type-child').each(function (index, item) {
            children += parseInt($(item).text());

        });
        rooms = $box.find('.quantity-selector-inner').length;

        $input.val(adults + trans('Adult') + " . " + children + trans('Child') + " . " + rooms + trans('Room'));


    }
    calculate();
}